

 /* MOBILE VERSION */ 
@media only screen and (max-width: 1199px) {
    header {
        background-color: #5C0F0F;
        font-weight: 800;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        text-align: right;
        display: grid
    }

        header h1 {
            color: white;
            font-family: Calibri;
        }

    .container {
        margin: 1rem;
        margin-top: 5rem;
    }

    .menu {
        list-style-type: none;
        padding: 0;
        display:initial
    }

    .menu-hidden {
        list-style-type: none;
        padding: 0;
        display: none
    }

    .menu li {
    }

    header a {
        background-color: rgba(1, 1, 61, 0);
        color: #f8f9fa;
        font-size: 30px;
        font-family: Calibri;
        font-weight: 400;
    }

    header a:hover {
        color: #a1a1a1;
    }

    .nav-icon {
        height: 2.5rem;
        width: 2.5rem
    }

    .mobile-menu-hamburger {
    }

    .menu-top {
        display: flex;
        justify-content: space-between;
    }
}



 /* PC VERSION */ 
@media only screen and (min-width:1200px ) {
    header {
        background-color: #5C0F0F;
        font-weight: 800;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 18.5rem;
        text-align: center;
        display: grid
    }

    header h1 {
        color: white;
        font-family: Calibri;
    }

    .container {
        margin: 1rem;
        margin-right: 9rem;
        margin-left: 21rem;
    }

    .menu {
        list-style-type: none;
        padding: 0;
    }
    
    .menu li {
    }

    header a {
        background-color: rgba(1, 1, 61, 0);
        color: #f8f9fa;
        font-size: 30px;
        font-family: Calibri;
        font-weight: 400;
    }

        header a:hover {
            color: #a1a1a1;
        }

    .nav-icon {
        height: 2.5rem;
        width: 2.5rem
    }

    .mobile-menu-hamburger{
        display:none;
    }

    .vertical-line {
        width: 2px;
        /* Adjust the width as needed */

        height: 25px;
        /* Adjust the height as needed */

        background-color: #7B2121;
        /* Change color if desired */

        margin: 0 auto;
        /* Center the line horizontally */

        transform: rotate(180deg);
        /* Rotate the line to make it vertical */
    }
}