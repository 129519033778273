@font-face {
    font-family: 'MarckScript-Regular';
    src: local('MarckScript-Regular'), url('./fonts/MarckScript-Regular.ttf') format('truetype');
}

body {
    padding: 0;
    margin: 0;
    background-color: #f2f2f2;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.container {
    font-family: Calibri;
}

.block {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 20px;
    padding: 15px;
    text-align: center;
    background-color: white;
}

/* MOBILE VERSION */
@media only screen and (max-width: 767px) {
}

/* TABLET VERSION */
@media only screen and (min-width:768px) and (max-width: 1199px) {    
}

/* PC VERSION */
@media only screen and (min-width:1200px ) {      
}