.container-header {
    margin: 3rem;
    font-family: 'MarckScript-Regular';
    font-weight: 400;
    font-size: 64px;
    text-align: center
}

.container-header-red {
    color: #5C0F0F
}

.container-header-black {
    color: black
}