.container p {
    margin: 1rem
}

.price-grid {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.price-box {
    width: 400px;
    margin: 1rem
}

.price-inner-box {
   width: 400px;
}

.price-inner-box > p{
    margin: 0;
    font-size: 30px;
}